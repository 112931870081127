<template>
  <div>
    <h1 class="title">Add a book to Actionstep</h1>
    <p>Our library is not yet complete. Simply add a book by its ISBN:</p>

    {{ messsage }}
    <form @submit.prevent="submitForm">
      <label>ISBN</label>
      <input v-model="bookForm.isbn" />
      <br /><input text="Submit" type="submit" />
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AddBook",
  data() {
    return {
      bookForm: {
        isbn: "",
      },
    };
  },
  methods: {
    submitForm() {
      const formData = new FormData();
      formData.append("isbn", this.bookForm.isbn);

      axios
        .post(`add-book`, formData)
        .then((response) => {
          console.log(response);
          this.message = "Book submitted.";
          this.$router.push("/")
        })
        .catch((error) => {
          console.log(error);
          this.message = error.response;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>