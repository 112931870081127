<template>
  <div class="columns is-centered">
    <div class="column is-half is-multline">
      <h1 class="title">{{ book.title }}</h1>
      <img :src="book.cover" height="200px">
      <h2>by {{ book.author }}</h2>
      <h2 class="subtitle">Add an Actionstep</h2>
      {{message}}
      <form @submit.prevent="submitActionstep">
        <input
          id="input-title"
          type="text"
          v-model="form.title"
          placeholder="Title"
        />
        <br /><textarea
          class="textarea"
          placeholder="Describe Actionstep more detailled"
          v-model="form.content"
        ></textarea>
        <br /><input text="Submit" type="submit" />
      </form>

      <div v-for="actionstep in actionsteps" class="card" :key="actionstep">
        <div class="card-header">
          <div class="card-header-title">
            {{ actionstep.title }} Points: {{actionstep.points}}
          </div>
          <div class="button" @click="upvoteActionstep(actionstep.id)">Upvote</div>

        </div>
        <div class="card-content">
          {{ actionstep.content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "BookDetail",
  data() {
    return {
      book: [],
      actionsteps: [],
      message: "",
      description: "",
      counter:1,
      form: {
        title: "",
        content: ""
      }
    };
  },
  mounted() {
    this.getBook();
    this.getActionsteps();
  },
  methods: {
    getBook() {
      const book_slug = this.$route.params.slug;

      axios
        .get(`bookdetails/${book_slug}`) // check right base url. always console
        .then((response) => {
          this.book = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getActionsteps() {
      const book_id = this.$route.params.id; //Not the best solution, but ok

      axios
        .get(`actionsteps/${book_id}`)
        .then(response => {
          this.actionsteps = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.message = error.response
        });
    },
    increment() {
        this.counter++;
    },
    submitActionstep(){
      const book_id = this.$route.params.id; //Not the best solution, but ok
      const formData = new FormData();
      formData.append('title', this.form.title)
      formData.append('content', this.form.content)

      axios.post(`add-actionstep/${book_id}`, formData)
         .then((response)=> {
           console.log(response)
           this.getActionsteps()
           this.message="Thank you for your Actionstep!"
         })
          .catch((error) => {
          console.log(error);
          this.message = error.response
        });
        },
        upvoteActionstep(actionstep_id){
          axios
          .get(`actionstep-upvote/${actionstep_id}`)
          .then(response => {
            console.log(response)
            this.getActionsteps()
          })
          .catch((error) => {
            console.log(error);
            this.message = error.response
          });
          
        }
        
  },
};
</script>
<style scoped>
#input-title {
  width: 100%;
}
</style>